<template>
  <div class="sub-table">
    <b-table-simple responsive fixed>
      <thead>
        <tr>
          <th width="3%" class="border-left"></th>
          <th width="10%"><Ellipsis>Situação</Ellipsis></th>
          <th><Ellipsis>Responsável</Ellipsis></th>
          <th><Ellipsis>Hora da realização</Ellipsis></th>
          <th><Ellipsis>Exame/Procedimento</Ellipsis></th>
          <th class="border-right"></th>
        </tr>
      </thead>

      <template v-if="groupedItems">
        <tbody>
          <template v-for="groupKey in Object.keys(groupedItems)">
            <tr
              v-for="(item, itemIdx) in groupedItems[groupKey]"
              :key="item.id"
              :class="[
                groupKey !== 'pending' &&
                itemIdx + 1 === groupedItems[groupKey].length
                  ? 'boundary'
                  : 'attendance'
              ]"
            >
              <td class="td-body checkbox-td">
                <label
                  class="checkbox"
                  v-if="
                    (
                      item.attendance === null ||
                      (item.attendance &&
                        !['FINISHED', 'CANCELED'].includes(
                          item.attendance.status
                        ))
                    ) 
                    && exam.status !== 'FINISHED'
                    && exam.status !== 'CANCELLED'
                  "
                >
                  <input
                    type="checkbox"
                    :value="item"
                    v-model="selected"
                    @click="selectCheck(item, groupKey)"
                    :disabled="isDisabled(item)"
                  />
                  <span
                    class="checkmark"
                    :class="{ disabled: isDisabled(item) }"
                  >
                    <Check />
                  </span>
                </label>
              </td>
              <td class="td-body">
                <template v-if="exam.status !== 'FINISHED' && exam.status !== 'CANCELLED' && item.attendance === null">
                  <b-button class="option pending" pill disabled>
                    <p><strong>Pendente</strong></p>
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    class="option pending"
                    v-if="
                      item.attendance?.status === 'PENDING' ||
                      item.attendance?.status === 'OPENED'
                    "
                    pill
                    disabled
                  >
                    <p><strong>Pendente</strong></p>
                  </b-button>
                  <b-button
                    class="option waiting"
                    v-if="item.attendance?.status === 'PAUSED'"
                    pill
                    disabled
                  >
                    <p><strong>Pausado</strong></p>
                  </b-button>
                  <b-button
                    class="option finished"
                    v-if="exam.status  === 'FINISHED' || item.attendance?.status === 'FINISHED'"
                    pill
                    disabled
                  >
                    <p><strong>Realizado</strong></p>
                  </b-button>
                  <b-button
                    class="option canceled"
                    v-if="exam.status === 'CANCELLED' || item.attendance?.status === 'CANCELED'"
                    pill
                    disabled
                  >
                    <p><strong>Cancelado</strong></p>
                  </b-button>
                </template>
              </td>
              <td class="td-body">
                {{ exam?.professional?.name }}
              </td>
              <td class="td-body">
                <span>
                  <Ellipsis>{{ parseDate(item) }}</Ellipsis>
                </span>
              </td>
              <td class="td-body">
                <template v-if="item.item.clinic_procedure">
                  <ProcedureLine
                    ellipsis
                    :procedure="item.item.clinic_procedure"
                    hideType
                  />
                </template>
                <template v-else>
                  <ProcedureLine ellipsis :procedure="item.item" hideType />
                </template>
              </td>
              <template
                v-if="item.attendance && item.attendance.status === 'FINISHED'"
              >
                <template
                  v-if="itemIdx === 0 && groupedItems[groupKey].length > 1"
                >
                  <td
                    class="td-body text-right p-3"
                    :rowspan="groupedItems[groupKey].length"
                  >
                    <span>
                      <b-button
                        :id="`btn-history-${item.id}`"
                        class="clipboard-button"
                        @click="
                          openAttendanceOnNewTab(
                            `pacientes/${exam.patient_id}/exame/${item.attendance.id}`
                          )
                        "
                      >
                        <Clipboard />
                      </b-button>
                      <b-tooltip
                        :target="`btn-history-${item.id}`"
                        placement="bottom"
                      >
                        Visualizar Histórico
                      </b-tooltip>
                    </span>
                  </td>
                </template>
                <template v-else-if="groupedItems[groupKey].length === 1">
                  <td class="td-body text-right p-3">
                    <span>
                      <b-button
                        :id="`btn-history-${item.id}`"
                        class="clipboard-button"
                        @click="
                          openAttendanceOnNewTab(
                            `pacientes/${exam.patient_id}/exame/${item.attendance.id}`
                          )
                        "
                      >
                        <Clipboard />
                      </b-button>
                      <b-tooltip
                        :target="`btn-history-${item.id}`"
                        placement="bottom"
                      >
                        Visualizar Histórico
                      </b-tooltip>
                    </span>
                  </td>
                </template>
              </template>

              <template
                v-if="
                  groupKey !== 'pending' &&
                  itemIdx === 0 &&
                  groupedItems[groupKey].length > 1 &&
                  !['FINISHED', 'CANCELED'].includes(item.attendance.status)
                "
              >
                <td
                  :rowspan="groupedItems[groupKey].length"
                  class="text-right td-body"
                >
                  <b-button
                    :id="`btn-refresh-${item.id}`"
                    class="refresh-button"
                    @click="
                      resetAttendanceAndAttendanceItems({
                        attendance: item.attendance.id
                      })
                    "
                  >
                    <Refresh />
                  </b-button>
                  <b-tooltip
                    :target="`btn-refresh-${item.id}`"
                    placement="bottom"
                  >
                    Reiniciar os Exames
                  </b-tooltip>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </template>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  props: {
    exam: Object
  },
  components: {
    Clipboard: () => import('@/assets/icons/clipboard.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    ProcedureLine: () => import('@/components/General/ProcedureLine'),
    Check: () => import('@/assets/icons/check.svg'),
    Refresh: () => import('@/assets/icons/refresh.svg')
  },
  data() {
    return {
      selected: [],
      showButton: false,
      invalidDate: '-000001-11-30T03:06:28.000000Z',
      groupedItems: null
    }
  },
  mounted() {
    this.groupItems()
    this.preSelectNotInitiatedExams()
  },
  methods: {
    /**
     * Group items by its statuses
     * @returns {Array}
     */
    groupItems() {
      const countExamsStatus = {
        PENDING: 0,
        FINISHED: 0,
        PAUSED: 0
      }
      this.groupedItems = this.exam.appointment_items.reduce((ac, it) => {
        if (it.attendance) {
          ac[it.attendance.id] === undefined
            ? (ac[it.attendance.id] = [it])
            : ac[it.attendance.id].push(it)
          countExamsStatus[it.attendance.status]++
        } else {
          ac.pending === undefined ? (ac.pending = [it]) : ac.pending.push(it)
          countExamsStatus.PENDING++
        }
        return ac
      }, {})

      return this.groupedItems
    },
    /**
     * Pre Selection of pending/not initiated attendances
     */
    preSelectNotInitiatedExams() {
      const pendingItems = []
      Object.keys(this.groupedItems).forEach((idx, k) => {
        if (idx === 'pending') {
          this.groupedItems[idx].forEach(procedure => {
            if (
              this.selected.length === 0 ||
              this.selected.some(item => item.item.type === procedure.item.type)
            ) {
              pendingItems.push({ procedure, action: 'add' })
              this.selected.push(procedure)
            }
          })
        }
      })

      if (pendingItems.length) {
        this.$emit('selected-exams', pendingItems)
      }
    },
    /**
     * Restart an attendance and its items
     * @param {Object} data<clinic, attendance>
     */
    resetAttendanceAndAttendanceItems(data) {
      const l = this.$loading.show()
      this.api
        .resetAttendanceAndAttendanceItems(data)
        .then(response => {
          // '589b58dc-9e46-4255-8517-de74aa98096a'
          this.$toast.success(response.data.message)
          this.$emit('resetAttendance')
        })
        .catch(error => this.$toast.error(error.message))
        .finally(() => l.hide())
    },

    parseDate(item) {
      const dateTime = item.attendance
        ? item.attendance.start_datetime
        : this.exam.start_datetime
      return this.moment(dateTime).format('DD/MM/YYYY HH[h]mm')
    },

    selectAllChecks(checked) {
      this.selected = []
      if (checked) {
        this.showButton = true
        this.selected.forEach(item => {
          if (['SCHEDULED'].includes(item.status)) {
            this.selected.push(item)
          }
        })
      } else {
        this.showButton = false
      }
    },
    /**
     * Mark items to be added or removed from the parent collection of selected objects
     * @param {Object} selected
     */
    selectCheck(selected, groupKey) {
      let remaining = []
      if (!event.target.checked) {
        remaining = this.selected.filter(item => item.id === selected.id)
        if (remaining.length) {
          remaining = remaining.map(procedure => {
            return { procedure, action: 'delete' }
          })
        }
      } else {
        remaining = this.selected
        if (remaining.length) {
          remaining = remaining.map(procedure => {
            return { procedure, action: 'delete' }
          })
        }
        this.selected = []
        const group = this.groupedItems[groupKey]
        group.forEach(item => {
          if (item.item.type === selected.item.type) {
            this.selected.push(item)
            remaining.push({ procedure: item, action: 'add' })
          }
        })
      }

      this.$emit('selected-exams', remaining)
    },

    isDisabled(procedure) {
      if (this.selected.length === 0) {
        return false
      }

      return this.selected.some(
        item => item.attendance_id !== procedure.attendance_id
      )
    },
    /**
     * self explained
     * @param url
     */
    openAttendanceOnNewTab(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';

th,
td {
  border: none !important;
  vertical-align: middle !important;
  .blue-text {
    font-weight: 700;
    color: var(--blue-500);
  }
}
.checkbox-td {
  justify-content: center;

  .checkbox {
    margin-bottom: 20px !important;
    vertical-align: middle !important;
    display: flex;
    .disabled {
      background-color: #d9dff2;
      border: none;
      svg {
        display: none;
      }
    }
  }
}

.border-left {
  border-radius: 18px 0px 0px 0px;
}

.border-right {
  border-radius: 0px 18px 0px 0px;
}
.sub-table {
  border: 1px solid var(--neutral-200);
  border-radius: 15px;
  margin: 1rem 0;
}

.sub-table tr {
  &.attendance {
    border: none;
  }
  &.boundary {
    border-bottom: 1px dashed var(--neutral-300);
  }
  &.boundary:last-child {
    border: none;
  }
}

.clipboard-button {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--blue-100);
  border: none;

  &:hover {
    background-color: var(--neutral-300);
  }

  svg {
    stroke: var(--blue-500);
  }
}
.refresh-button {
  padding: 12px 16px;
  height: 100%;
  border-radius: 8px;
  background-color: var(--blue-100);
  border: none;

  &:hover {
    background-color: var(--blue-300);
  }

  svg {
    stroke: var(--blue-500);
    fill: var(--blue-500);
  }
}

.option {
  // margin-top: 10px;
  font-size: 12px;
  border: none;

  &.pending {
    background: #ffebcc;
    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.waiting {
    background: #e2ccff;
    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.finished {
    background: #dbfff0;
    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.canceled {
    background: #f11828;
    p {
      display: inline;
      color: #f6f2ff !important;
    }
  }
}
</style>
